import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import WebFont from 'webfontloader';
import { unregister as unregisterServiceWorker } from './registerServiceWorker'


WebFont.load({
  google: {
    families: ['Source Code Pro', 'monospace'],
    families: ['Oswald', 'sans-serif']
  }
});

ReactDOM.render(<App />, document.getElementById('root'));
unregisterServiceWorker();
