import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ProjectDialog from './ProjectDialog';
import ButtonBase from '@material-ui/core/ButtonBase';
import publicSpeaking from '../publicSpeaking.png'; // Import using relative path
import coop from '../coop.png'; // Import using relative path
import ggj from '../ggj.png'; // Import using relative path
import gameborhood from '../gameborhood.png'; // Import using relative path
import influence from '../influence.png'; // Import using relative path
import personal from '../personal.png'; // Import using relative path

class Project extends Component {
  state = {
    open: false,
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    if (this.props.project) {
      const project = this.props.project
      var image

      switch (project.image) {
        case 'publicSpeaking':
          image = publicSpeaking
          break;
        case 'coop':
          image = coop
          break;
        case 'ggj':
          image = ggj
          break;
        case 'influence':
          image = influence
          break;
        case 'personal':
          image = personal
          break;
        case 'gameborhood':
          image = gameborhood
          break;
        default:
          image = personal
          break;
      }

      return (
        <Grid item xs={12} sm={6} md={4}>
          <ButtonBase onClick={this.handleClickOpen}>
            <Paper className={classes.text} style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover' }} elevation={3}>
              <div className={classes.overlay}><div className={classes.overlayText}><h2>{project.title}</h2></div></div>
            </Paper>
          </ButtonBase>
          <ProjectDialog open={this.state.open} onClose={this.handleClose} project={project} />
        </Grid>)
    }
  }
}

const styles = ({
  text: {
    width: 300,
    height: 300
  },
  overlay: {
    lineHeight: 25,
    height: '100%',
    opacity: 0,
    '&:hover': {
      backgroundColor: '#E4B1B6',
      opacity: 0.8
    },
    textAlign: 'center'
  },
  overlayText: {
    lineHeight: 1.5,
    display: 'inline-block',
    color: '#3E4765',
    verticalAlign: 'middle',
    fontSize: 20
  }
});

export default withStyles(styles)(Project);
