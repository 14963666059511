import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import {isMobile} from 'react-device-detect';

import React, { Component } from 'react';

class Footer extends Component {
  render() {

    const { classes } = this.props;

    if (this.props.data) {
      var networks = this.props.data.social.map(function (network) {
        return <li className={classes.socialLink} key={network.url}><a className={classes.link} href={network.url} target={!isMobile && '_blank'}><i className={network.className}></i></a></li>
      })
    }

    return (
      <Paper className={classes.footer}>
        <ul className={classes.socialLinks}>
          {networks}
        </ul>
        <br />
        &copy; Copyright 2020 Katherine P Brennan
      </Paper>
    );
  }
}

const styles = theme => ({
  footer: {
    margin: 'auto',
    maxWidth: 1000,
    marginTop: 30,
    marginBottom: 20,
    backgroundColor: '#D9B856',
    backgroundSize: 'cover',
    textAlign: 'center',
    color: '#3E4765',
    padding: 20
  },
  socialLinks: {
    margin: '18px 0 10px 0',
    padding: 0,
    fontSize: '30px'
  },
  socialLink: {
    display: 'inline-block',
    margin: 0,
    padding: 0,
    marginLeft: '42px',
    color: '#CCA35F',
    '&:first-child': {
      marginLeft: 0
    }
  },
  link: {
    color: '#F7F6F4',
    '&:hover': {
      color: "#AC6A55"
    }
  }
  //footer a:hover, footer a:focus { color: #F4CED2; }
});


export default withStyles(styles)(Footer);
