import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import {isMobile} from 'react-device-detect';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ProjectDialog extends React.Component {
  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  render() {
    const { classes } = this.props;

    if (this.props.project) {
      const project = this.props.project
      const outsideLink = project.url && (<a href={project.url} className={classes.link} target={!isMobile && '_blank'}><Button className={classes.button} color="primary" autoFocus>
        Check it out!
    </Button></a>)
      return (
        <Dialog open={this.props.open} onClose={this.handleClose} TransitionComponent={Transition}>
          <h2 className={classes.title}>{project.title}</h2>
          <div>
            <p className={classes.desc}>{(project.title !== 'Public Speaking' && project.category) || publicSpeakicongContent}</p>
          </div>
          <DialogActions>
            {outsideLink}
            <Button className={classes.button} onClick={this.handleClose} color="secondary">
              close
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else {
      return <div />
    }
  }
}

ProjectDialog.propTypes = {
  classes: PropTypes.object.isRequired,
}

const publicSpeakicongContent = (
  <div> 
    In addition to announcements at events regularly, as well as ceremonies and demos at multiple game jams I have the following public speaking experience:
    <ul>
      <li>
        2019
        <ul>
          <li><a href="https://youtu.be/Dknj4WQwzEQ?t=76" target={!isMobile && '_blank'}>Making games makes me feel better</a></li>
          <li><a href="https://www.meetup.com/St-Louis-Game-Developers/events/257965300/" target={!isMobile && '_blank'}>Game Jam Q&A</a></li>
        </ul>
      </li>
      <li>
        2018
        <ul>
          <li><a href="https://youtu.be/xwZjjxyhVKw?t=4609" target={!isMobile && '_blank'}>Game Jam Q&A</a></li>
        </ul>
      </li>
      <li>
        2017
        <ul>
          <li><a href="http://pixelpopfestival.com/programming-schedule-sunday-2017/" target={!isMobile && '_blank'}>You can code!</a></li>
          <li><a href="https://www.meetup.com/St-Louis-Game-Developers/events/241725024/" target={!isMobile && '_blank'}>Have an open discussion about Improving Game Development in St. Louis</a></li>
          <li><a href="https://youtu.be/kMsNaBWZVCU" target={!isMobile && '_blank'}>How Games Can Change The World (Panelist)</a></li>
        </ul>
      </li>
    </ul>
  </div>
)

const styles = {
  color: "#3E4765",
  borderStyle: "solid",
  borderColor: "#3E4765",
  title: {
    marginLeft: 25
  },
  desc: {
    marginLeft: 25,
    marginRight: 25
  },
  button: {
    fontFamily: 'Oswald, sans-serif',
    fontSize: 20,
    marginRight: 20,
    marginBottom: 10  
  },
  link: {
    textDecoration: 'none'
  }
}

export default withStyles(styles)(ProjectDialog);
