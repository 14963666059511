import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Project from './Project';
import ScrollableAnchor from 'react-scrollable-anchor'


class Projects extends Component {
  state = { open: false }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  render() {
    const { classes } = this.props;

    if (this.props.data) {
      return (
        <ScrollableAnchor id={'projects'}>

          <Grid container spacing={24} className={classes.grid}>
            {this.props.data.projects.map(project => (
              <Project project={project} key={project.title} />
            ))}
          </Grid>
        </ScrollableAnchor>
      );
    } else {
      return <section id="projects" />
    }
  }
}

const styles = ({
  grid: {
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 1000
  }
});

export default withStyles(styles)(Projects);
