import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {isMobile} from 'react-device-detect';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

class About extends Component {
  render() {
    const { classes } = this.props;
    if (this.props.data) {
      var bio = this.props.data.bio;
      var email = this.props.data.email;

      return (
        <section id="about">
          <Paper className={`${classes.about} ${classes.section}`} elevation={10}>
            <h2>About</h2>
            <p className={classes.bio}>{bio}</p>
          </Paper>
          <Grid container spacing={24} className={classes.root}>
            <Grid item xs>
              <Card>
                <CardMedia component="img" image="/images/profilepic.jpg"/>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={`${classes.contact} ${classes.section}`}>
                <h2>Contact</h2>
                <div className={classes.bullets}>
                  <a className={classes.link} href='mailto:contact@katherinepbrennan' target={!isMobile && '_blank'}><i className='fa fa-envelope' /></a> {email} <br />
                  <a className={classes.link} href='https://twitter.com/kbrdthenerd' target={!isMobile && '_blank'}><i className='fab fa-twitter' /></a> @kbrdthenerd
                </div>
              </Paper>
              <Paper className={`${classes.resume} ${classes.section}`}>
                <h2>CV</h2>
                <div className={classes.bullets}>
                  <a className={classes.link} href='https://www.linkedin.com/in/katherinepbrennan/' target={!isMobile && '_blank'}><i class="fab fa-linkedin"></i></a> LinkedIn <br />
                </div>
              </Paper>
              <Paper className={`${classes.projects} ${classes.section}`}>
                <h2>Projects</h2>
                <div className={classes.bullets}>
                  <a className={classes.link} href='#projects'><i className='fa fa-arrow-down' /></a> Highlights below! <br />
                  <a className={classes.link} href="http://github.com/kbrdthenerd" target={!isMobile && '_blank'}><i className='fab fa-github' /></a> GitHub <br />
                  <a className={classes.link} href="https://kbrdthenerd.itch.io/" target={!isMobile && '_blank'}><i className='fab fa-itch-io' /></a> itch.io
                </div>
              </Paper>
            </Grid>
          </Grid>
        </section>
      );
    } else {
      return <section id="about" />
    }
  }
}

const styles = theme => ({
  root: {
    marginTop: 20
  },
  section: {
    padding: 25,
    paddingTop: 5,
    color: "#ffffff"
  },
  about: {
    color: "#3E4765",
    borderStyle: "solid",
    borderColor: "#3E4765",
    backgroundColor: '#F7F6F4'
  },
  contact: {
    backgroundColor: '#3E4765'
  },
  resume: {
    color: "#3E4765",
    marginTop: 20,
    backgroundColor: '#F7F6F4',
    borderStyle: "solid",
    borderColor: "#3E4765"
  },
  projects: {
    marginTop: 20,
    backgroundColor: "#3E4765"
  },
  link: {
    color: '#D9B856',
    '&:hover': {
      color: "#e4b1b6"
    }
  }
});

export default withStyles(styles)(About);
