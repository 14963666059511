import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Image from '../header-background-copy.jpg'; // Import using relative path
import {isMobile} from 'react-device-detect';


class Header extends Component {
  render() {
    const { classes } = this.props;

    if (this.props.data) {
      var name = this.props.data.name;
      var networks = this.props.data.social.map(function (network) {
        return <li key={network.name}><a href={network.url} target={!isMobile && '_blank'}><i className={network.className}></i></a></li>
      })
    }

    return (
      <Paper className={classes.root}>
        <header id="home">
          <div className="row banner">
            <div className="banner-text">
              <h1 className="responsive-headline">{name}</h1>
              <Grid container spacing={24} className={classes.grid}>
                <Grid item xs={12} sm={6} md={4}><h2><span>Software Engineer</span></h2></Grid>
                <Grid item xs={12} sm={6} md={4}><h2><span>Game Developer</span></h2></Grid>
                <Grid item xs={12} sm={6} md={4}><h2><span>Community Leader</span></h2></Grid>
              </Grid>
              <ul className="social">
                {networks}
              </ul>
            </div>
          </div>
        </header>
      </Paper>
    );
  }
}

const styles = ({
  root: {
    margin: 'auto',
    marginBottom: 20,
  }
});

export default withStyles(styles)(Header);
